import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Contact from "@/views/Contact";
import About from "@/views/About";
import Service from "@/views/Service";
import Access from "@/views/Access";
import News from "@/views/News";
import NewsDetail from "@/views/NewsDetail";
import Recruit from "@/views/Recruit";
import ServiceDetail from "@/views/ServiceDetail";
import Business from "@/views/Business";
import Policy from "@/views/Policy";
import Greeting from "@/views/Greeting";
import Strength from "@/views/Strength";
import Philosophy from "@/views/Philosophy";
import History from "@/views/History";

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            pathType: 'home',
            title: 'ホーム',
            content: {
                keywords: '『醫健泰株式会社』国際医療コーディネート、医療通訳、医療サーポト、がん治療・救急医療・地域連携をより強化した新しい医療体制で、地域の、日本の、世界の医療拠点を目指します.「世界に通用するハイレベルな医療を提供する」という決意を名前に込めて、医療の国際化に対応できるよう、最先端の医療機器を導入し、専門性の高いスタッフとともに満足度の高い医療の提供を目指します。そして、地域においても高水準の医療を提供することで、「安心」を感じていただき、「信頼」に応えていきます。',
                description: '醫健泰株式会社'
            }
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            pathType: 'contact',
            title: 'お問い合わせ',
            content: {
                keywords: '『醫健泰株式会社』国際医療コーディネート、医療通訳、医療サーポト、世界に通用するハイレベルな医療を提供する」という決意を名前に込めて、医療の国際化に対応できるよう、最先端の医療機器を導入し、専門性の高いスタッフとともに満足度の高い医療の提供を目指します。そして、地域においても高水準の医療を提供することで、「安心」を感じていただき、「信頼」に応えていきます。',
                description: 'お問い合わせ'
            }
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            pathType: 'about',
            title: '会社概要',
            content: {
                keywords: '『醫健泰株式会社』国際医療コーディネート、医療通訳、医療サーポト、がん治療・救急医療、最先端の医療機器を導入し、専門性の高いスタッフとともに満足度の高い医療の提供を目指します。そして、地域においても高水準の医療を提供することで、「安心」を感じていただき、「信頼」に応えていきます。',
                description: ''
            }
        }
    },
    {
        path: '/service',
        name: 'service',
        component: Service,
        meta: {
            pathType: 'service',
            title: '事業内容',
            content: {
                keywords: '『醫健泰株式会社』医療の国際化に対応できるよう、最先端の医療機器を導入し、専門性の高いスタッフとともに満足度の高い医療の提供を目指します。そして、地域においても高水準の医療を提供することで、「安心」を感じていただき、「信頼」に応えていきます。',
                description: ''
            }
        }
    },
    {
        path: '/access',
        name: 'access',
        component: Access,
        meta: {
            pathType: 'access',
            title: 'アクセス',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            pathType: 'news',
            title: 'ニュース',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/news/:id',
        name: 'newsDetail',
        component: NewsDetail,
        meta: {
            pathType: 'newsDetail',
            title: 'ニュース詳細',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/serviceDetail/:id',
        name: 'serviceDetail',
        component: ServiceDetail,
        meta: {
            pathType: 'serviceDetail',
            title: 'サービス詳細',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/recruit',
        name: 'recruit',
        component: Recruit,
        meta: {
            pathType: 'recruit',
            title: '採用情報',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/business',
        name: 'business',
        component: Business,
        meta: {
            pathType: 'business',
            title: '事業内容',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/policy',
        name: 'policy',
        component: Policy,
        meta: {
            pathType: 'policy',
            title: '個人情報保護方針',
            content: {
                keywords: '',
                description: 'Iken Tai - 個人情報保護方針'
            }
        }
    },
    {
        path: '/greeting',
        name: 'greeting',
        component: Greeting,
        meta: {
            pathType: 'greeting',
            title: '社長挨拶',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/strength',
        name: 'strength',
        component: Strength,
        meta: {
            pathType: 'strength',
            title: '醫健泰の強み',
            content: {
                keywords: '多様化する医療ニーズに応えるべく様々な取り組みを行っています。肺がん／肝臓がん／膵臓がん',
                description: ''
            }
        }
    },
    {
        path: '/philosophy',
        name: 'philosophy',
        component: Philosophy,
        meta: {
            pathType: 'philosophy',
            title: '経営理念',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        meta: {
            pathType: 'history',
            title: '醫健泰の沿革',
            content: {
                keywords: '',
                description: ''
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/*
 * title 要素を制御する
 */
router.beforeEach((to, from, next) => {
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    document.title = to.meta.title + ' - 醫健泰'
    next()
})
export default router