<template>
  <div class="yikt_contact_body common_body">
    <div class="yikt_contact_text" data-aos="fade-right">
      <p>お客様の個人情報につきましては、他の目的に使用することはございません、弊社「個人情報保護規定」に基づいて責任をもって管理いたします。
      <br/>詳しくは弊社「<a href="/policy">プライバシーポリシー</a>」をご参照ください。</p>
      <p class="mt15">代表電話 <a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
      <p class="mt15">採用については<a href="/recruit">こちら</a></p>
    </div>
    <div>
      <div class="yikt_contact_step_body" data-aos="fade-right">
        <el-steps :active="step" simple v-if="ww>850">
          <el-step title="必要事項の入力" icon="el-icon-edit"></el-step>
          <el-step title="入力の内容確認" icon="el-icon-tickets"></el-step>
          <el-step title="診療仮申込完了" icon="el-icon-circle-check"></el-step>
        </el-steps>
        <el-steps :active="step" simple v-else>
          <el-step title="入力" icon="el-icon-edit"></el-step>
          <el-step title="確認" icon="el-icon-tickets"></el-step>
          <el-step title="完了" icon="el-icon-circle-check"></el-step>
        </el-steps>
      </div>
      <div>
        <div class="yikt_contact_form_body" v-show="step===0">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="260px" :label-position="ww>850?'left':'top'">
            <el-form-item label="相談者種別" prop="typeContact" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.typeContact">
                <el-radio v-for="(item,index) in radioList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="代理人の方のお名前とご関係" prop="DaiName" class="el-form-item">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.DaiName"
                        placeholder="(例) 醫健泰 太郎 母親"></el-input>
            </el-form-item>
             <el-form-item label="お名前" prop="name">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.name"
                        placeholder="(例) 醫健泰 太郎"></el-input>
            </el-form-item>
            <el-form-item label="アルファベット" prop="nameFurigana">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.nameFurigana"
                        placeholder="(例) IKENTAI TAIROU"></el-input>
            </el-form-item>
            <el-form-item label="生年月日" prop="birthDate" required="false">
               <el-config-provider :locale="ja">
                <el-date-picker
                    v-model="ruleForm.birthDate"
                    type="date"
                    placeholder="生年月日を選んでください"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :locale="ja"/>
               </el-config-provider>
            </el-form-item>
            <el-form-item label="性別" prop="sex" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.sex">
                <el-radio v-for="(item,index) in sexList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="電話番号" class="is-required">
              <div class="yikt_contact_form_3" data-aos="fade-right" :data-aos-duration="500">
                <el-form-item prop="phone" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone" @input="checkNumber('phone')" :maxlength="4"
                            :placeholder="'(例) ' + HomeInfo.Tel.split('-')[0]"></el-input>
                </el-form-item>
                <span class="yikt_contact_form_-">-</span>
                <el-form-item prop="phone2" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone2" @input="checkNumber('phone2')" :maxlength="4"
                            :placeholder="'(例) ' + HomeInfo.Tel.split('-')[1]"></el-input>
                </el-form-item>
                <span class="yikt_contact_form_-">-</span>
                <el-form-item prop="phone3" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone3" @input="checkNumber('phone3')" :maxlength="4"
                            :placeholder="'(例) ' + HomeInfo.Tel.split('-')[2]"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="メールアドレス" prop="email">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.email" :placeholder="'(例) ' + HomeInfo.Email"></el-input>
            </el-form-item>
            <el-form-item label="住所" prop="address">
              <el-input v-model="ruleForm.address" :data-aos-duration="500" data-aos="fade-right"
                        :placeholder="'(例) 〒' + HomeInfo.PostCode + ' ' + HomeInfo.Address"></el-input>
            </el-form-item>
            <el-form-item label="国籍" prop="nationality">
               <el-radio-group v-model="ruleForm.nationality">
                　<el-radio v-for="(item,index) in nationalityList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="第一言語" prop="lan1">
                <el-select v-model="ruleForm.lan1" :data-aos-duration="500" data-aos="fade-right"
                           placeholder="第一言語を選択してください">
                  <el-option
                      v-for="(value, key, index) in options"
                      :key="index"
                      :label="value.called"
                      :value="value.called">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="第二言語" prop="lan2">
              <el-select v-model="ruleForm.lan2" :data-aos-duration="500" data-aos="fade-right"
                           placeholder="第二言語を選択してください">
                  <el-option
                      v-for="(value, key, index) in options"
                      :key="index"
                      :label="value.called"
                      :value="value.called">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="相談目的" prop="consultation" id="consultation">
                <el-checkbox v-model="ruleForm.consultation" v-for="(item,index) in consultationList" :key="index" :label="index">{{ item.called }}</el-checkbox>
                <br/>
                ※（来日されるのがベターですが、ご本人情報をもとに、診断内容や治療方法等に関して専門医に助言のみを行うことを目的とし、必ず来日の必要はございません）
            </el-form-item>
            <el-form-item label="ご相談内容" prop="content">
              <el-input data-aos="fade-right" :data-aos-duration="500" type="textarea" v-model="ruleForm.content" placeholder="今回のご相談内容を出来る限り詳しくご入力下さいませ。" :rows="6"></el-input>
            </el-form-item>
            <el-form-item label="希望医療機関" prop="kibokikan" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.kibokikan">
                <el-radio v-for="(item,index) in kibokikanList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="受診国" prop="kuni">
              <el-input v-model="ruleForm.kuni" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 中国"></el-input>
            </el-form-item> 
            <el-form-item label="医療機関名" prop="iryokikan">
              <el-input v-model="ruleForm.iryokikan" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 聖路加国際病院"></el-input>
            </el-form-item>
            <el-form-item label="診療科" prop="iryoka">
              <el-input v-model="ruleForm.iryoka" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 一般内科"></el-input>
            </el-form-item>
            <el-form-item label="診断名" prop="shidanmin">
              <el-input v-model="ruleForm.shidanmin" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 健診・精密検査"></el-input>
            </el-form-item>
             <el-form-item label="手術" prop="shojutsu" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.shojutsu">
                <el-radio v-for="(item,index) in shojutsuList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="提出可能な最新医療紙資料" prop="data" class="data_list" data-aos="fade-right" :data-aos-duration="500">
               <el-checkbox v-model="ruleForm.data" v-for="(item,index) in dataList" :key="index" :label="index">{{ item.called }}</el-checkbox>
            </el-form-item> 
            <el-form-item label="提出可能な最新医療画像" prop="image_data" class="data_list" data-aos="fade-right" :data-aos-duration="500">
               <el-checkbox v-model="ruleForm.image_data" v-for="(item,index) in imgDataList" :key="index" :label="index">{{ item.called }}</el-checkbox>
            </el-form-item>
            <el-form-item label="現在の状況" prop="status" class="data_list" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.status">
                <el-radio v-for="(item,index) in statusList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>   
            <el-form-item label="来日歴" prop="his_jp" class="data_list" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.his_jp">
                <el-radio v-for="(item,index) in shojutsuList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>   
            <el-form-item label="来日ビザ" prop="visa_jp" class="data_list" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.visa_jp">
                <el-radio v-for="(item,index) in visa_jpList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="ビザ種類" class="data_list" prop="visatype">
               <el-select v-model="ruleForm.visatype" :data-aos-duration="500" data-aos="fade-right"
                           placeholder="ビザ種類を選択してください">
                  <el-option
                      v-for="(value, key, index) in visaList"
                      :key="index"
                      :label="value.called"
                      :value="value.called">
                  </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="パスポートナンバー" class="data_list" prop="pusport">
              <el-input v-model="ruleForm.pusport" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) YK1234567"></el-input>
            </el-form-item>

            <el-form-item label="医療ビザの申請" class="data_list" prop="approve">
              <el-radio-group v-model="ruleForm.approve">
                <el-radio v-for="(item,index) in approveList" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="日本滞在予定期間" prop="yoteDate" class="data_list">
               <el-config-provider :locale="ja">
                <el-date-picker
                    v-model="ruleForm.yoteDateFrom"
                    type="date"
                    placeholder="開始日を選んでください"
                    format="YYYY-MM-DD"
                    @input="yoteDateFromInput"
                    @change="yoteDateFromInput"
                    value-format="YYYY-MM-DD"
                    :locale="ja"/>〜
                <el-date-picker
                    v-model="ruleForm.yoteDateTo"
                    type="date"
                    placeholder="終了日を選んでください"
                    format="YYYY-MM-DD"
                    @input="yoteDateFromInput"    
                    @change="yoteDateFromInput"
                    value-format="YYYY-MM-DD"
                    name="yoteDateTo_in"
                    :locale="ja"/>
               </el-config-provider>
               <div class="el-form-item__error" v-if="isInValidDateTo">開始日が終了日より遅い日付です。</div>
            </el-form-item>
            <el-form-item label="診察第一希望日" prop="shisatsuDate1" class="data_list">
               <el-config-provider :locale="ja">
                <el-date-picker
                    v-model="ruleForm.shisatsuDate1"
                    type="datetime"
                    placeholder="診察第一希望日を選んでください"
                    format="YYYY-MM-DD HH:mm"
                    value-format="YYYY-MM-DD HH:mm"
                    :locale="ja"/>
               </el-config-provider>
            </el-form-item>
            <el-form-item label="診察第二希望日" prop="shisatsuDate2" class="data_list">
               <el-config-provider :locale="ja">
                <el-date-picker
                    v-model="ruleForm.shisatsuDate2"
                    type="datetime"
                    placeholder="診察第二希望日を選んでください"
                    format="YYYY-MM-DD HH:mm"
                    value-format="YYYY-MM-DD HH:mm"
                    :locale="ja"/>
               </el-config-provider>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="yikt_contact_form_agree_content_title">診療仮申込における個人情報の取り扱いについて
              </div>
              <el-collapse-transition data-aos="fade-right" :data-aos-duration="500">
                <div>
                  <div class="yikt_contact_form_agree_content">
                    <h5>
                        (１)	事業者の名称
                    </h5>
                    <p>{{HomeInfo.CompanyName}}</p>
                    <br />
                    <h5>
                      (２)	個人情報保護管理者の氏名又は職名、所属及び連絡先
                    </h5>
                    <p>
                        個人情報保護管理者：代表取締役　{{HomeInfo.Representative}}<br/>
                        メール：{{HomeInfo.Email}}<br/>
                        ＴＥＬ：{{HomeInfo.Tel}}<br/>
                    </p>
                    <br />
                    <h5>(３)	個人情報の利用目的</h5>
                    <p>診療仮申込、資料請求に対応するするため。</p>
                    <br />
                    <h5>(４)	個人情報の第三者提供について</h5>
                    <p>
                      取得した個人情報は法令等による場合を除いて第三者に提供することはありません。
                    </p>
                    <br />
                    <h5>(５)	個人情報の取扱いの委託について</h5>
                    <p>取得した個人情報の取扱いの全部又は、一部を委託することがあります。委託に際しては、個人情報の取扱いについて当社水準と同等かそれ以上の安全を担保できる事業者を選定します。</p>
                    <br />
                    <h5>(６)	個人情報を与えなかった場合に生じる結果</h5>
                    <p>個人情報のご提供は任意です。ただし、診療仮申込に必要な個人情報をご提供いただけない場合は、診療仮申込内容に回答出来ない可能性があります。</p>
                    <br /> 
                    <h5>(７)	開示対象個人情報の開示等および問い合わせ窓口について</h5>
                    <p>
                       ご本人からの請求により、当社が保有する開示対象個人情報に関する開示、利用目的の通知、内容の訂正・追加または削除、利用停止、消去および第三者提供の停止、第三者提供記録の開示に応じます。<br/>
                       ご連絡窓口は、下記「当社の個人情報の取扱いに関する苦情、相談等の問合せ先」を参照してください。
                    </p>
                    <br />
                    <h5>(８)	本人が容易に認識できない方法による個人情報の取得</h5>
                    <p>クッキーやウェブビーコン等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。</p>
                    <br />
                    <h5>(９)	個人情報の安全管理措置について</h5>
                    <p>
                      取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。<br/>
                      診療仮申込おいて取得した個人情報は、当社内において保管期間を定め、保管期間経過後は削除又は破棄いたします。
                    </p>
                    <br />
                    <h5>(１０)	個人情報保護方針</h5>
                    <p>当社ホームページの個人情報保護方針をご覧下さい。</p>
                    <br />
                    <h5>(１１)	当社の個人情報の取扱いに関する苦情、相談等の問合せ先</h5>
                    <p style="">
                     【個人情報診療仮申込窓口】<br/>
                      窓口責任者：個人情報保護管理者<br/>
                      住　所：〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}<br/>
                      会　社：{{HomeInfo.CompanyName}}<br/>
                      ＴＥＬ：{{HomeInfo.Tel}}<br/>
                      メール：{{HomeInfo.Email}}
                    </p>
                  </div>
                </div>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc is-required" data-aos="fade-right" :data-aos-duration="500">
              <el-checkbox v-model="ruleForm.agree">
                個人情報の取り扱いについて同意します
              </el-checkbox>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="tc">
               <!-- <input type="button" class="thm-btn" @click="submitForm('ruleForm')" value=" 入力内容を確認する " /> -->
                <a href="javascript:void(0);" @click="submitForm('ruleForm')" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right" tabindex="-1"><span>&emsp;入力内容を確認する&emsp;<i class="bi bi-arrow-right"></i></span></a>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="step===1" class="yikt_contact_form_check_body">
          <table class="yikt_contact_form_check_table">
            <tbody>
              <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="yikt_contact_form_check_title">相談者種別</td>
               <td class="yikt_contact_form_check_content">{{ getListOfLabel(ruleForm.typeContact, radioList) }}</td>
             </tr>  
             <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.DaiName!=''">
               <td class="yikt_contact_form_check_title">代理人の方のお名前とご関係</td>
               <td class="yikt_contact_form_check_content">{{ ruleForm.DaiName }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="yikt_contact_form_check_title">お名前</td>
               <td class="yikt_contact_form_check_content">{{ ruleForm.name }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="yikt_contact_form_check_title">アルファベット</td>
               <td class="yikt_contact_form_check_content">{{ ruleForm.nameFurigana }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right">
               <td class="yikt_contact_form_check_title">生年月日</td>
               <td class="yikt_contact_form_check_content">{{ ruleForm.birthDate }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right">
               <td class="yikt_contact_form_check_title">性別</td>
               <td class="yikt_contact_form_check_content">{{  getListOfLabel(ruleForm.sex, sexList) }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="yikt_contact_form_check_title">電話番号</td>
               <td class="yikt_contact_form_check_content">
                 {{ ruleForm.phone + '-' + ruleForm.phone2 + '-' + ruleForm.phone3 }}
               </td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="yikt_contact_form_check_title">メールアドレス</td>
              <td class="yikt_contact_form_check_content">{{ruleForm.email}}</td>
            </tr>
             <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.address!=''">
               <td class="yikt_contact_form_check_title">住所</td>
              <td class="yikt_contact_form_check_content">{{ruleForm.address}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.nationality!=''">
               <td class="yikt_contact_form_check_title">国籍</td>
              <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.nationality,nationalityList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.lan1!=''">
               <td class="yikt_contact_form_check_title">第一言語</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.lan1}}</td>
            </tr> 
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.lan2!=''">
               <td class="yikt_contact_form_check_title">第二言語</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.lan2}}</td>
            </tr> 
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.lan2!=''">
               <td class="yikt_contact_form_check_title">第二言語</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.lan2}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500">
              <td class="yikt_contact_form_check_title">相談目的</td>
              <td class="yikt_contact_form_check_content type_content">{{getListOfCheckBox(ruleForm.consultation,consultationList)}}</td>
            </tr>
             <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.content!=''">
               <td class="yikt_contact_form_check_title">ご相談内容</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.content}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.kibokikan!=''">
               <td class="yikt_contact_form_check_title">希望医療機関</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.kibokikan,kibokikanList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.kuni!=''">
               <td class="yikt_contact_form_check_title">受診国</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.kuni}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.iryokikan!=''">
               <td class="yikt_contact_form_check_title">医療機関名</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.iryokikan}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.iryoka!=''">
               <td class="yikt_contact_form_check_title">診療科</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.iryoka}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.shidanmin!=''">
               <td class="yikt_contact_form_check_title">診断名</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.shidanmin}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.shojutsu!=''">
               <td class="yikt_contact_form_check_title">手術</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.shojutsu,shojutsuList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.data!=''">
               <td class="yikt_contact_form_check_title">提出可能な最新医療紙資料</td>
               <td class="yikt_contact_form_check_content">{{getListOfCheckBox(ruleForm.data,dataList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.image_data!=''">
               <td class="yikt_contact_form_check_title">提出可能な最新医療画像</td>
               <td class="yikt_contact_form_check_content">{{getListOfCheckBox(ruleForm.image_data,imgDataList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.status!=''">
               <td class="yikt_contact_form_check_title">現在の状況</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.status,statusList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.his_jp!=''">
               <td class="yikt_contact_form_check_title">来日歴</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.his_jp,shojutsuList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.visa_jp!=''">
               <td class="yikt_contact_form_check_title">来日ビザ</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.visa_jp,visa_jpList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.visatype!=''">
               <td class="yikt_contact_form_check_title">ビザ種類</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.visatype}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.pusport!=''">
               <td class="yikt_contact_form_check_title">パスポートナンバー</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.pusport}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.approve!=''">
               <td class="yikt_contact_form_check_title">医療ビザの申請</td>
               <td class="yikt_contact_form_check_content">{{getListOfLabel(ruleForm.approve,approveList)}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.yoteDateFrom!=''">
               <td class="yikt_contact_form_check_title">日本滞在予定期間</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.yoteDateFrom + '〜' + ruleForm.yoteDateTo}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.shisatsuDate1!=''">
               <td class="yikt_contact_form_check_title">診察第一希望日</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.shisatsuDate1}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.shisatsuDate2!=''">
               <td class="yikt_contact_form_check_title">診察第二希望日</td>
               <td class="yikt_contact_form_check_content">{{ruleForm.shisatsuDate2}}</td>
            </tr>
            </tbody>
          </table>
          <div class="tc" data-aos="fade-right" :data-aos-duration="500">
              <!-- <input type="button" class="thm-btn" @click="sendMail()" :loading="sending" value="　送信　" />&emsp;&emsp; -->
              <a href="javascript:void(0);" @click="backform()" class="octf-btn octf-btn-primary octf-btn-icon" tabindex="-1"><span>前画面へ戻る<i class="bi bi-arrow-right"></i></span></a>
              <!-- <input type="button" class="thm-btn" style="background-image:linear-gradient(90deg, #61d8ff 0%, #6168ff 51%, #61d8ff 100%)" @click="backform()" value="前画面へ戻る" /> -->
              &emsp;&emsp;
              <a href="javascript:void(0);" @click="sendMail()" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right" tabindex="-1"><span>&emsp;診療仮申込&emsp;<i class="bi bi-arrow-right"></i></span></a>
          </div>
        </div>
        <div v-show="step===2" class="yikt_contact_form_send_ok_body tc">
          <i class="el-icon-success yikt_contact_form_send_ok_ico"></i>
          <br />
          <p class="f18" data-aos="fade-right" :data-aos-duration="500">
              診療仮申込ありがとうございました。<br />
              診療仮申込は正常に完了しました。<br /><br />
          </p>
          <div class="tc mt30" data-aos="fade-right" :data-aos-duration="500">
             <!-- <input type="button" @click="backtop()" class="thm-btn" style="background-image:linear-gradient(90deg, #61d8ff 0%, #6168ff 51%, #61d8ff 100%)"  value=" トップページへ戻る " /> -->
             <a href="javascript:void(0);" @click="backtop()" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right" tabindex="-1"><span>トップページへ戻る<i class="bi bi-arrow-right"></i></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const typelist = [{called:'患者様本人'},{called:'患者様本人以外'}];
const sexList = [{called:'男性'},{called:'女性'}];
const visaList = [{called:'医療'},{called:'短期滞在'},{called:'介護'},{called:'留学'},{called:'研修'},{called:'家族滞在'}
                ,{called:'高度専門職'},{called:'経営・管理'},{called:'技術・人文知識・国際業務'}
                ,{called:'特定技能'},{called:'技能実習'},{called:'企業内転勤'},{called:'興行'}
                ,{called:'法律・会計業務'},{called:'報道'},{called:'外交'},{called:'公用'},{called:'教授'},{called:'芸術'}
                ,{called:'宗教'},{called:'研究'},{called:'芸術'},{called:'特定活動'},{called:'永住者'}
                ,{called:'日本人の配偶者等'},{called:'永住者の配偶者等'},{called:'定住者'}
];
const options = [{called:'中国語'},{called:'英語'},{called:'日本語'},{called:'韓国語'},{called:'モンゴル語'},{called:'タガログ語'}
                  ,{called:'ベトナム語'},{called:'ラオス語'},{called:'クメール語'},{called:'タイ語'},{called:'ビルマ語'},
                  {called:'マレー語'},{called:'インドネシア語'},{called:'その他'}];
const kibokikanList = [{called:'あり'},{called:'なし'}];
const visa_jpList = [{called:'申請済'},{called:'申請中'}];
const approveList = [{called:'申請する'},{called:'申請しない'},{called:'未定（必要性の証明必要）'}];
const shojutsuList = [{called:'有'},{called:'無'}];
const statusList = [{called:'治療中'},{called:'治療中ではない'}];
const imgDataList = [{called:'CT'},{called:'ET-CT'},{called:'MRI'},{called:'MRA'},{called:'エコー'},{called:'X線'},{called:'その他'}];
const dataList =  [{called:'血液検査結果'},{called:'手術記録診断書'},{called:'組織検査結果'},{called:'採尿検査結果'},{called:'心電図検査結果'},{called:'その他'}];
const nationalityList = [{called:'中国'},{called:'日本'},{called:'米国'},{called:'韓国'},{called:'その他'}];
const consultationList = [{called:'検診'},{called:'検査・治療'},{called:'セカンドオピニオン'},{called:'美容治療'},{called:'歯科治療'}];
import ja from 'element-plus/lib/locale/lang/ja'
export default {
  name: "MailFrom",
  data() {
    return {
      step: 0,
      agree: false,
      ja: ja,
      radioList : typelist,
      kibokikanList: kibokikanList,
      visa_jpList: visa_jpList,
      approveList: approveList,
      shojutsuList: shojutsuList,
      statusList: statusList,
      imgDataList: imgDataList,
      dataList: dataList,
      options: options,
      visaList:visaList,
      sexList: sexList,
      nationalityList: nationalityList,
      consultationList: consultationList,
      ww: window.innerWidth,
      isInValidDateTo :false,
      ruleForm: {
        typeContact: 0,
        DaiName:'',
        name: '',
        nameFurigana: '',
        birthDate: '',
        sex: 0,
        phone: '',
        phone2: '',
        phone3: '',
        email: '',
        address:'',
        nationality: 0,
        lan1:'',
        lan2:'',
        consultation: [0],
        content: '',
        kibokikan:1,
        kuni: '',
        iryokikan: '',
        iryoka: '',
        shidanmin: '',
        shojutsu:1,
        data:[],
        image_data:[],
        status:1,
        his_jp:1,
        visa_jp:'',
        visatype:'',
        pusport:'',
        approve:'',
        yoteDateFrom:'',
        yoteDateTo:'',
        shisatsuDate1:'',
        shisatsuDate2:'',
        agree: false
      },
      sending: false,
      rules: {
        name: [
          {
            required: true,
            message: 'お名前を入力してください',
            trigger: 'blur'
          }
        ],
        birthDate: [
          {
            required: true,
            message: '生年月日を入力してください',
            trigger: 'blur'
          }
        ],
        nameFurigana: [
          {
            pattern: /^[a-zA-Z\s]+$/u,
            required: true,
            message: 'アルファベットを入力してください',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        phone2: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        phone3: [
          {
            pattern: /^[0-9]{4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'メールアドレスを正しく入力してください',
            trigger: 'blur'
          }
        ],
        yoteDateFrom:[
          {
            required: false,
            message: '',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: 'ご相談内容を正しく入力してください',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    getListOfLabel(index,list) {
      if (list && list.length>=index) {
        return list[index].called || '';
      }
      return '';
    },
    getListOfCheckBox(idxs,list) {
      let labels = [];
      if (list && idxs && list.length>=idxs.length) {
        idxs.forEach(index=>{
          labels.push(list[index].called || '');
        });
      }
      return labels.join('、');
    },
    yoteDateFromInput() {
        this.isInValidDateTo = false;
        if(new Date(this.ruleForm.yoteDateFrom) > new Date(this.ruleForm.yoteDateTo)) {
            document.querySelector('input[name="yoteDateTo_in"]').style= 'border-color: var(--el-color-danger);'
            this.isInValidDateTo = true;
        } else {
           this.isInValidDateTo = false;
           document.querySelector('input[name="yoteDateTo_in"]').style= '';
        }
    },
    checkNumber(name) {
      let text = this.ruleForm[name]
      this.ruleForm[name] = text.replace(/[^\d]/g, '')
    },
    backtop() {
      window.location.href = '/'
    },
    send() {
      this.step = 2
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    backform() {
      this.step = 0
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(new Date(this.ruleForm.yoteDateFrom) > new Date(this.ruleForm.yoteDateTo)) {
              this.yoteDateFromInput();
              document.body.scrollTop = document.documentElement.scrollTop = 500
              return false
          }
          if (!this.ruleForm.agree) {
            this.$message({
              message:
                  '「個人情報の取り扱いについて同意します」をチェックしてください。',
              type: 'warning'
            })
            return false
          }
          this.step = 1
          document.body.scrollTop = document.documentElement.scrollTop = 0
        } else { 
          document.body.scrollTop = document.documentElement.scrollTop = 200
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    sendMail() {
      let params = {
        typeContact: this.getListOfLabel(this.ruleForm.typeContact, this,radioList),
        DaiName: this.ruleForm.DaiName,
        name: this.ruleForm.name,
        nameFurigana: this.ruleForm.nameFurigana,
        birthDate: this.ruleForm.birthDate,
        sex: this.getListOfLabel(this.ruleForm.sex, this.sexList),
        phone: this.ruleForm.phone +'-'+ this.ruleForm.phone2  +'-'+ this.ruleForm.phone3,
        email: this.ruleForm.email,
        address:this.ruleForm.address,
        nationality: this.getListOfLabel(this.ruleForm.nationality,this.nationalityList),
        lan1: this.ruleForm.lan1,
        lan2: this.ruleForm.lan2,
        consultation: this.getListOfCheckBox(this.ruleForm.consultation, this.consultationList),
        content: this.ruleForm.content.replace(/\r?\n/g, '<br>'),
        kibokikan: this.getListOfLabel(this.ruleForm.kibokikan,this.kibokikanList),
        kuni: this.ruleForm.kuni,
        iryokikan: this.ruleForm.iryokikan,
        iryoka: this.ruleForm.iryoka,
        shidanmin: this.ruleForm.shidanmin,
        shojutsu: this.getListOfLabel(this.ruleForm.shojutsu,this.shojutsuList),
        data: this.getListOfCheckBox(this.ruleForm.data,this.dataList),
        image_data: this.getListOfCheckBox(this.ruleForm.image_data,this.imgDataList),
        status: this.getListOfLabel(this.ruleForm.status,this.statusList),
        his_jp: this.getListOfLabel(this.ruleForm.his_jp,this.shojutsuList),
        visa_jp: this.getListOfLabel(this.ruleForm.visa_jp,this.visa_jpList),
        visatype: this.ruleForm.visatype,
        pusport: this.ruleForm.pusport,
        approve: this.getListOfLabel(this.ruleForm.approve,this.approveList),
        yoteDate: this.ruleForm.yoteDateFrom + '〜' + this.ruleForm.yoteDateTo,
        shisatsuDate1: this.ruleForm.shisatsuDate1,
        shisatsuDate2: this.ruleForm.shisatsuDate2
      }
      this.sending = true,
          $.ajax({
            type: "POST",
            url: "/api/mail.php",
            dataType: "json",
            data: params,
            success: (res) => {
              debugger
              this.sending = false
              if (!res) {
                this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
                return false
              }
              if (res.code === 1) {
                this.send()
              } else {
                this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
              }
            },
            error:(res,aaa,bbb)=> {
               this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
            }
          });
    }
  }
}
</script>
