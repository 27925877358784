<template>

<a href="#" id="scroll-top" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>

<header>

<nav>
	<div class="header-menu-area agency-header">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-xxl-2 col-xl-2 col-lg-2 col-sm-6 col-6 order-0 order-lg-0">
					<div class="logo text-left">
						<a href="/">
						  <img src="/assets/images/footer-logo.png" class="logo-uview" alt="" style="max-width: 145px;">
						  <img src="/assets/images/logo-1.png" class="logo-view" alt="" style="max-width: 145px;">
						</a>
					</div>
				</div>
				<div class="col-xxl-10 col-xl-10 col-lg-10 col-sm-3 col-3 order-2 order-lg-1">
					<a href="javascript:void(0)" class="hidden-lg hamburger">
						<span class="h-top"></span>
						<span class="h-middle"></span>
						<span class="h-bottom"></span>
					</a>
					<nav class="main-nav">
						<div class="logo mobile-ham-logo d-lg-none d-block text-left">
								  <img src="/assets/images/footer-logo.png" class="logo-uview" alt="" style="max-width: 145px;">
						  <img src="/assets/images/logo-1.png" class="logo-view" alt="" style="max-width: 145px;">
						</div>
						<ul>
							<li>
								<a href="/" :class="{'active':pathType==='home'}">ホーム</a>
							</li>
							<li>
                                <a href="/about" :class="{'active':pathType==='about'}">会社情報</a>
                            </li>
							<li>
								<a href="/business" :class="{'active':pathType==='business'}">事業内容</a>
							</li>
							<li><a href="/access" :class="{'active':pathType==='access'}">アクセス</a></li>
							<li><a href="/recruit" :class="{'active':pathType==='recruit'}">採用情報</a></li>
							<li><a href="/news" :class="{'active':pathType==='news'}">お知らせ</a></li>
							<li><a href="/contact" :class="{'active':pathType==='contact'}">診療仮申込</a></li>
							<li><a href="https://oa.yikt.co.jp" class="search-popup__toggler main-nav__search" onclick="location.href = 'https://oa.yikt.co.jp'"  id="fa-user-circle" style="    font-size: 35px;
    position: relative;
    top: 7px;"><i class="far fa-user-circle"></i></a></li>
						</ul>
					</nav>
				</div>

			</div>
		</div>
	</div>
</nav>
<!-- Hero Area -->
<div class="hero-slide-wrap owl-carousel" id="hero-slide">
	<div class="hero-slide-item hero-slide-item-agency position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2 style="line-height:45px;">医療資源の深いサポート<br/></h2>
						<h2  style="line-height:45px;" class="mt-1 mt-lg-0">
							世界権威の健康機関と協力し、<br />
							わかりやすいコミュニケーション、高速予約、<br />
							専門家からのフォローアップから追跡できる完全な運行を支持するまでの業務全般
						</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="octf-btn octf-btn-icon octf-btn-secondary home_img_right" href="/contact">Contact Us<i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency-2.png);"></div>
		<img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="">
		<!-- <img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt=""> -->
		<video class="background" autoplay muted loop poster="/assets/images/hero/hero-agency-2.png">
			<source src="/assets/images/video/banner_1_cis.mp4?ver=1.0.1" type="video/mp4"/>
		</video>
	</div>
	<div class="hero-slide-item position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2 style="line-height:45px;">醫健泰メディカルヘルスケアサービス<br/></h2>
						<h2 style="line-height:45px;" class="mt-1 mt-lg-0">
							お客様のため、力を尽くす<br />
							グローバルな最先端のヘルスケアのためのグリーンチャネルの構築<br />
							開かれた国際的な視点であなたの指先で健康を触れることができる
						</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right" href="/#service">Our Service <i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency.png);"></div>
		<!-- <img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt=""> -->
		<video class="background" autoplay muted loop poster="/assets/images/hero/hero-agency.png">
			<source src="/assets/images/video/banner_2_cis.mp4?ver=1.0.1" type="video/mp4"/>
		</video>
	</div>
	<div class="hero-slide-item position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2 style="line-height:45px;">一元的かつ多方位健康管理<br/></h2>
						<h2 style="line-height:45px;" class="mt-1 mt-lg-0">
							患者様を中心に、<br />
							プロアクティブな日本式健康介入を使って、<br />
							お一人おひとりの患者様に完璧な健康管理プランを提供する
						</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="octf-btn octf-btn-primary octf-btn-icon home_img_right" href="/about">About Our Company<i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/banner_3_cis.png);"></div>
		<!-- <img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt=""> -->
		<video class="background" autoplay muted loop poster="/assets/images/hero/banner_3_cis.png">
			<source src="/assets/images/video/banner_3_cis.mp4?ver=1.0.1" type="video/mp4"/>
		</video>
	</div>
</div>
<!-- Hero Area End -->
</header>
</template>
<style scoped>
.video-wrapper {
  height: 100vh;
  position: relative;
}

.background { 
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; 
  /*opacity: 0.6;*/
  object-fit: cover; 
}
</style>
<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() { 
    const route = useRoute();
    const pathType = ref('')
    const ww = Window.innerWidth;
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = newPathType;
		if(newPathType=="home") {
		  document.getElementById('hero-slide').style.display='block';
		} else {
			 document.getElementById('hero-slide').style.display='none';
		}
      }
    )
    onMounted(()=>{
       pathType.value = route.meta.pathType || location.pathname.substring(1);
       if(window.innerWidth < 900) {
        //   $('#'+ pathType.value  + '_current').addClass('current');
       }
    })
    return {pathType,ww}
  }
}
</script>