<template>
<div class="breadcrumb-area inner-banner breadcrumb-area-strength main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Our Strength</h4>
                        <h1 class="mt-3">醫健泰の強み</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">  
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
          <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500" class="color-1"><span>Strength</span></p>
      </div>
      <div class="row post_content">
            <h2>１．高齢者に幸福な晩年を与えられるように努めている</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
                「銀髪の国」の日本からの康養パターンを導入し、省級の養老コミュニティプロジェクト、
                養老不動産プロジェクトなどを手に入れて、日本の高級会員式の康養センターを作り上げた。
                ハイレベルな活動施設で、醫健泰が康養サービスを最大限に磨き、専門の看護師、フルフォーマルな高級ホテルサービス、スマートケア、
                包括的な身体予防検査、高齢者に幸福な晩年を与えられるように努めている
            </p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>２．国際化資源統合</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
                日本医療国際化機構MEJとの連携<br/>
                日本の大手介護医療機関と戦略協定を締結する<br/>
                Emergent Systemなどの国際専門医療プラットフォームを導入するす。
            </p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>3．専門チームが運営</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
                専門医学の知識を備えた看護師を中心とした看護システム<br/>
                介護人材のため、長期的かつ短期的な来日スキル研修を行う<br/>
                中国人の習慣をはかった日本医療運営科学管理制度
            </p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Strength",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>
