<template>
<section class="why-section">
	<div class="floated-icon left"><img src="/assets/images/resource/stones-left.svg" alt="" title=""></div>
	<div class="floated-icon right"><img src="/assets/images/resource/stones-right.svg" alt="" title=""></div>
	<div class="auto-container">

		<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500"><p><span>Why Choose Iken Tai</span></p><h3>醫健泰を選ぶ理由</h3></div>

		<div class="row clearfix">
			<div class="left-col col-xl-3 col-lg-4 col-md-6 col-sm-12">
				<div class="inner">
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-1.svg" alt=""></div>
							<h4>医療機関向け情報案内</h4>
							<div class="text">外国人患者を受け入れる医療機関へ外国人医療に関する情報を案内しています（無料）。</div>
						</div>
					</div>
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-2.svg" alt=""></div>
							<h4>遠隔コンサルティング</h4>
							<div class="text">日本の権威ある医師と顔を合わせ、世界の先進的な医療サービスを享受できる。日本の権威ある専門家から診療のアドバイスを得て、医師の時間と費用を節約して、患者の治療効率を高めることを努める</div>
						</div>
					</div>
				</div>
			</div>

			<div class="right-col col-xl-3 col-lg-4 col-md-6 col-sm-12">
				<div class="inner">
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-4.svg" alt=""></div>
							<h4>再生医療サービス</h4>
							<div class="text">一対一の個人化管理は、顧客が慢性病を改善し、自身の亜健康状態を調整することを助け、体の自己更新、自己調整、活力を回復し、お客様に内部から外への活力を取り戻す</div>
						</div>
					</div>
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-5.svg" alt=""></div>
							<h4>醫健泰と日本医師会</h4>
							<div class="text">あなたにぴったりの医療機関と正確に一致させること、あなただけの医療プランをカスタマイズすること</div>
						</div>
					</div>
				</div>
			</div>

			<div class="image-col col-xl-6 col-lg-4 col-md-12 col-sm-12" data-aos="fade-right" data-aos-duration="500">
				<div class="inner">
					<div class="image-box"><img src="/assets/images/resource/why-image-1.png" alt=""></div>
				</div>
			</div>

		</div>
	</div>
	<div class="floated-icon right"><img src="/assets/images/resource/floated-icon-right-2.svg" alt="" title=""></div>
</section>

<section class="about-two">
	<img src="/assets/images/shape/about-2-bg-1-1.png" class="about-two__bg-shape-1" alt="">
	<div class="container">
		<img src="/assets/images/shape/about-2-bg-1-2.png" class="about-two__bg-shape-2" alt="">
		<div class="row">
			<div class="col-lg-6">
				<div class="about-two__images wow fadeInLeft" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
					<img src="/assets/images/resource/hero-1.png" class="float-bob-y" alt="">
				</div>
				<!-- /.about-two__images -->
			</div>
			<!-- /.col-lg-6 -->
			<div class="col-lg-6">
				<div class="about-two__content">
					<div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
						<p><span>About Company</span></p>
						<h3>醫健泰について</h3>
					</div>
					<!-- /.block-title text-left -->
					<p data-aos="fade-right" :data-aos-duration="500">
							医学の知識と国際的な視点が豊富な醫健泰は、すべての患者に日本の最先端のグリーン通路を作り、日本の先進的な医療技術を中国に導入することを目的としている。日本の先端資源と日本医師チームの厳格なサービスを伝達するとともに、国際医療発展に貢献する
							<br />	<br />
							“大きな成果を上げ、意味のあることをするために国と人々のために最善を尽くすこと”	<br />
							醫健泰メディカルサービスは，お客様に専門的な医療サービスと高度な医療理念を提供するために設立された医療サービス企業である。先端技術を用いて一人一人に健康、病気の予防、アンチエイジングなどの面で健康で豊富な生活を提供することを使命とし、人類の健康の増進と生活品質の向上に貢献する。
					</p>

					<div class="about-two__counter-wrap">
						<div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
							<a href="/about" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right">&emsp; 醫健泰概要&emsp;<i class="flaticon-right-arrow-1"></i>
							</a>
						</div>
						
					</div>
					<!-- /.about-two__counter-wrap -->
				</div>
				<!-- /.about-two__content -->
			</div>
			<!-- /.col-lg-6 -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>

<section class="hero_aria">
		<div class="container custom_container">
			<div class="row align-items-center">
			<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
			     <p><span style="background-image: linear-gradient(90deg, #fff 0%, #f1ebeb 100%);">Join Us Now</span></p>					
				<h3 style="color: #fff;" data-aos="fade-right" :data-aos-duration="500">
				  介護福祉士の育成
				  <br>活躍できる舞台がこにある。
				</h3>
			</div>
			<div class="col-lg-6">
				<div class="hero_content wow fadeInUp" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;color: #fff;">
					<h4 data-aos="fade-right" style="color: #fff;font-size:20px;line-height:40px" :data-aos-duration="500">日本の老年介護の先進環境を积极的に利用して、中国の高品質な介護福祉士の人材を育成するとともに中国の介護環境を改善することをできるよう頑張っている
					<br/>&nbsp;
					</h4>
				    <a href="/recruit" class="octf-btn octf-btn-icon octf-btn-secondary">&emsp;Join Us Now&emsp;<i class="bi bi-arrow-right"></i></a>
				</div>
			</div>
			<div class="col-lg-6" data-aos="fade-right" :data-aos-duration="500">
				<div class="section_img wow fadeInLeft aos-init aos-animate inner" id="scene">
					<img data-depth="0.2" class="image-box" src="/assets/images/joinus.png" alt="">
				</div>
			</div>
		</div>
	</div>
</section>

<div class="professional-service-area service-area-design-agency pt-120 pb-96">
	<div class="container" id="service">
	   <div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
	      <p><span>What We Are Expert At</span></p><h3>サービス内容</h3>
	   </div>
		<div class="row mt-60">
			<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<h3>国際医療サービス</h3>
					<p class="mt-4">世界をリードする医療レベルの権威病院を合わせて、お客様に最適な専門家機関を適合させて、高品質な海外受診を享受する</p>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<h3>日本での人材育成</h3>
					<p class="mt-4">中国と日本の医学発展に貢献し，質の高い介護人材を養成し，各国の医学留学生の日本での就職を促進する</p>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<h3>ハイエンドヘルスケアサービス</h3>
					<p class="mt-4">プロアクティブでハイレベルの医療サービスでご健康を提供し、カスタマイズされたサービスなどを利用して、お客様に至れり尽くせりの標準化された親身的なサービス体験を提供する</p>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="sponsors-one style-two">
        <div class="bg-layer" style="background-image: url(/assets/images/background/image-5.png);"></div>
        <div class="auto-container">
		   <div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
	      	<p><span>世界の最先端の医療機構および健診機関</span></p><h3>協力機構</h3>
	       </div>
            <div class="carousel-box">
                <div class="sponsors-carousel owl-theme owl-carousel owl-loaded owl-drag">
                <div class="owl-stage-outer">
				    <div class="owl-stage" style="transform: translate3d(-1200px, 0px, 0px); transition: all 0.7s ease 0s; width: 2400px;">
					<div class="owl-item" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image">
									<a href="#"><img src="/assets/images/resource/sponsor-1.png" alt=""></a>
								</div>
							</div>
						</div>
					</div>
					<div class="owl-item" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-2.png" alt=""></a></div>
							</div>
                    	</div>
					</div>
					<div class="owl-item" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-3.png" alt=""></a></div>
							</div>
                    	</div>
					</div>
					<div class="owl-item" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-4.png" alt=""></a></div>
							</div>
                    	</div>
					</div>
					<div class="owl-item" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-5.png" alt=""></a></div>
							</div>
                    	</div>
					</div>
					<div class="owl-item active" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-1.png" alt=""></a></div>
							</div>
						</div>
					</div>
					<div class="owl-item active" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-2.png" alt=""></a></div>
							</div>
						</div>
					</div>
					<div class="owl-item active" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-3.png" alt=""></a></div>
							</div>
						</div>
					</div>
					<div class="owl-item active" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-4.png" alt=""></a></div>
							</div>
                    	</div>
					</div>
					<div class="owl-item active" style="width: 270px; margin-right: 30px;">
						<div class="sponsor-block">
							<div class="inner-box">
								<div class="image"><a href="#"><img src="/assets/images/resource/sponsor-5.png" alt=""></a></div>
							</div>
                   		 </div>
					</div>
					</div>
				</div>
					<div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span class="prev-btn far fa-angle-left"></span></button><button type="button" role="presentation" class="owl-next disabled"><span class="next-btn far fa-angle-right"></span></button></div><div class="owl-dots"><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot active"><span></span></button></div></div>
            </div>
        </div>
    </section>
<div class="blog-area pt-120 pb-96">
	<div class="container">
		<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
	      <p><span>Our Blog Posts</span></p><h3>お知らせ</h3>
	    </div>
		<div class="row mt-60">
			<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right" :data-aos-duration="500" v-for="(item, index) in newsList" :key="index">
				<div class="blog-wrap overflow-hidden blog-one__single">
					<div class="blog-img overflow-hidden">
						<router-link :to="`/news/${item.id}`"><img class="w-100" :src="item.img" alt="" style="width:370px;height:220px;"></router-link>
					</div>
					<div class="blog-content">
						<p class="blog-meta"><router-link :to="`/news/${item.id}`">Iken Tai News</router-link> <span>|</span> {{ item.time }}</p>
						<div class="mt-3 mb-3" style="font-size: 16px;"><router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></div>
						<div class="blog-btn">
							<router-link :to="`/news/${item.id}`" class="thm-btn blog-one__btn" style="padding: 1.5px 32.5px;">Read More</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<style scoped>
  .inner-box>div.icon-box>img {
	  filter: invert(51%) sepia(67%) saturate(3036%) hue-rotate(559deg) brightness(98%) contrast(412%);
  }
  
</style>

<script>
// banner設定
function slickDots (){
    if($('.slick-dots').length) {
        let slickList = $('.slick-dots')[0].childNodes;
        if (slickList.length) {
            var point = 1;
            $('#bannercroll').css('background-image','')
            for (var i=1; i <= slickList.length; i++) {
                point = i;
                const className = slickList[i-1].className;
                if(i==1 && className) { 
                    $('#bannercroll').css('background-image','')
                    $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
                else if(i==2 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%)');
                    // -webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%);
                } else if(i==3 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(47, 18, 137, 0.7) 0%, #4f1eab 100%)');
                } else {
                    //   $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
            }
        }
    }
	if ($('.sponsors-carousel').length) {
		$('.sponsors-carousel').owlCarousel({
			loop:false,
			margin:30,
			nav:true,
			smartSpeed: 700,
			autoplay: true,
			autoplayTimeout:7000,
			navText: [ '<span class="prev-btn far fa-angle-left"></span>', '<span class="next-btn far fa-angle-right"></span>' ],
			responsive:{
				0:{
					items:1
				},
				600:{
					items:2
				},
				768:{
					items:3
				},
				992:{
					items:3
				},
				1200:{
					items:4
				}
			}
		});    		
	}
}
window.setInterval(slickDots, 100);
import { onMounted, ref,onUnmounted } from "vue";
export default {
  name: "Home",
  setup() {
   
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      getNewsList();
      resize();
	   $('.owl-nav').hide();
    //   initSlick();
    });
    function resize() {
      const height = $(window).height();
      $('#bannercroll').css('height',height);
    }
    window.addEventListener('resize', resize)
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>

