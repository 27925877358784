<template>
    <!-- Start Iken Tai breadcrumb section -->
  <div class="inner-banner breadcrumb-area-info3 main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Recruit</h4>
                        <h1 class="mt-3">採用情報</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px">
    <div class="container">
 
        <div class="yikt_recruit_body common_body" data-aos="fade-right">
          
          <div class="yikt_recruit_content_body">
            <div>
            <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                  <p><span>Mid Career Hire</span></p>
                  <h4>キャリア採用情報</h4>
             </div>
              <div>
                <table class="yikt_recruit_table">
                  <tbody>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>雇用形態</div></td>
                      <td><div>正社員　（アルバイトも相談可能）</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>職務概要</div></td>
                      <td><div>マーケティング営業等</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>要求</div></td>
                      <td>
                        <div>
                          大卒<br />
                          中国語・日本語ビジネスレベル以上（外国籍の場合、日本語検定試験一級）<br />
                          年齢・性別・国籍不問<br />
                         【歓迎要件】医療業界経験者、医療/美容通訳士経験者優先
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>勤務地</div></td>
                      <td><div>東京本社、その他顧客先（都内各地）</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>勤務時間</div></td>
                      <td><div>①弊社事業所の場合／9:30～18:30
                        <br />②お客様事業所の場合／お客様環境による</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="yikt_recruit_title_td"><div>面接場所</div></td>
                      <td><div>東京本社<br />
                           〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
           <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500" style="margin-top:50px;">
            <p><span>Recruit Step</span></p>
            <h4>キャリア採用先行ステップ</h4>
          </div>
            <div class="mt30">
              <div class="yikt_recruit_step_body">
                <div class="yikt_recruit_step_body">
                  <div data-aos="fade-right">
                    <div class="yikt_recruit_step_title">STEP.1 応募</div>
                    <div class="yikt_recruit_step_content">応募まずは電話、メールでご連絡いただいた上で郵送またはメール送信で応募書類を提出してください。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="yikt_recruit_step_title">STEP.2 書類選考</div>
                    <div class="yikt_recruit_step_content">書類選考を行います。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="yikt_recruit_step_title">STEP.3 一次面接</div>
                    <div class="yikt_recruit_step_content">人物重視で選考を行います。どんなことでもお気軽にお話しください。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="yikt_recruit_step_title">STEP.4 条件面談 二次面談</div>
                    <div class="yikt_recruit_step_content">採用条件等の提示面談を実施します。現場担当者による詳細な技術内容の確認面談を行う場合もあります。</div>
                  </div>
                </div>
              </div>
            </div>
           <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500" style="margin-top:50px;">
              <p><span>Entry</span></p>
              <h4>エントリー</h4>
            </div>
            <div class="mt30">
              <el-row :gutter="24" data-aos="fade-right">
                <el-col :lg="12">
                  <div class="yikt_recruit_window_body" data-aos="fade-right">
                    <div class="yikt_recruit_window_title"><i class="el-icon-s-promotion"></i>フォームからの応募はこちらから</div>
                    <div class="yikt_recruit_window_content" data-aos="fade-right">
                      送信先：<br /><a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a><br />
                      携帯電話/Mail等のご連絡先をご記入ください<br />
                      不明な点がございましたらご連絡ください<br />
                    </div>
                  </div>
                </el-col>
                <el-col :lg="12" data-aos="fade-right">
                  <div class="yikt_recruit_window_body" data-aos="fade-right">
                    <div class="yikt_recruit_window_title"><i class="el-icon-phone"></i>お電話からの応募はこちらから</div>
                    <div class="yikt_recruit_window_content" data-aos="fade-right">
                      個人情報取扱いの苦情申し出先<br />
                      管理部（苦情相談窓口責任者）<br />
                      TEL：<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a><br />
                      会社名称：{{HomeInfo.CompanyName}}<br />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <br/>
        </div>
      </div>
  </section>
  <!-- End reinfo_contact section -->
</template>

<script>
import {onMounted, ref} from "vue";
import MailFrom from "../components/MailFrom";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Recruit",
  components:{ MailFrom,BreadCrumb},
  data() {
    return {
      active: 0
    }
  },
  setup(){
    const currentTab = ref('MailFrom')
    onMounted(() => {
      document.title = '採用情報 - 醫健泰株式会社';
    })
    return {currentTab}
  },
}
</script>