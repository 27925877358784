<template>
<div class="inner-banner breadcrumb-area-info-sc main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Greeting</h4>
                        <h1 class="mt-3">社長挨拶</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container" style="background-image: url(/assets/images/syac.png);
                                    background-repeat: no-repeat;
                                    background-size: unset;
                                    background-position: right bottom;">  
      <div class="row post_content">
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">代表取締役社長</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">{{HomeInfo.Representative}}<br/><br/></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">皆様には平素より格別のご厚情を賜り有難く厚くお礼申し上げます。<br/><br/></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
            醫健泰株式会社は、創業以来、お客様からご依頼いただいた経営上の課題のニーズに対し、最適な経営コンサルティングサービスのご提供を通じ、ご満足いただけるようお手伝いをいたしております。
        </p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
           これまでご用命いただいた貴重な経験をもとに、旧来にも増して時代のニーズに沿った、オリジナリティの高い経営コンサルティングサービスの開発・ご提供に鋭意努力いたす所存です。
        </p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">今後とも相変わらずのご指導・ご鞭撻を賜りますよう、何卒よろしくお願い申し上げます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p><br/>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">お客様のご要望には、誠心誠意、総力を挙げてお応えいたします。</p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>

<style scoped>
p{
      line-height: 40px;
      font-size: 16px;
}
</style>
