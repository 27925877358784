<template>
<!-- Start Iken Tai breadcrumb section -->
<div class="inner-banner breadcrumb-area-info main-banner" >
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Accesst</h4>
                        <h1 class="mt-3">アクセス</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 
  <section class="faq-one__form-wrap faq-one__home-three faq-one" style="margin-top:30px;">
  　　<div class="container">
      <div class="row  breadcrumb-area-map">
          <div class="col-lg-12 d-flex">
              <div class="my-auto">
                  <div class="block-title text-left text-phone-center" data-aos="fade-right" :data-aos-duration="500">
                      <p><span>Access</span></p>
                  </div>
                  <!-- /.block-title text-center -->
                  <div class="accrodion-grp" data-grp-name="career-one__accrodion">
                      <div class="accrodion " data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="address_1">所在地</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                      <div class="accrodion" data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="car_1">アクセス方法</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    千代田線「新御茶ノ水駅」から徒歩3分<br>
                                    半蔵門線「神保町駅」から徒歩5分<br>
                                    東西線「竹橋駅」から徒歩6分<br>
                                    JR中央・総武線「御茶ノ水駅」「大手町駅」「神田駅」から徒歩10分以内<br>
                                    淡路町駅徒歩3分、新御茶ノ水駅徒歩3分<br>
                                    小川町駅徒歩3分、神保町駅徒歩5分
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                      <div class="accrodion" data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="tel_1">代表電話</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    <a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a><br><br><br>
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /.my-auto -->
          </div>
          <!-- /.col-lg-6 -->
      </div>
      <!-- /.row -->
    </div>
  <!-- /.container -->
  </section>
  <!-- /.faq-one__form-wrap -->
  <section class="contact-map__block">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span> Map </span></p>
            <h3>醫健泰の所在地</h3>
        </div>
        <!-- <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        <iframe :src="HomeInfo.MapUrl" class="google-map__contact-page" allowfullscreen data-aos="fade-right" :data-aos-duration="500"></iframe>
    </div>
    <!-- /.container -->
  </section>
  <!-- /.contact-map__block -->
</template>



<script>

export default {
  name: "Access",
  methods: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
}
</script>
