import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'

import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'


AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});

let HomeInfo = {
    Tel: '03-6824-7166',
    Fax: '03-6824-7168',
    PostCode: '101-0054',
    Email: 'info@yikt.co.jp',
    Address: '東京都千代田区神田錦町2-2-1',
    Building: 'KANDA SQUARE 11-120',
    CompanyName: '醫健泰株式会社',
    CompanyNo: 'Medical Health Tai Co., Ltd.',
    Abbreviation: '（略称：Iken Tai）',
    Representative: '堀内　雪',
    Katakana: 'ホリウチ　ユキ',
    Capital: '2000',
    Banks: '',
    CreateDate: '2016年01月12日',
    YearSell: '',
    HakenNo: '',
    CTO: '',
    // <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4614.456446037608!2d139.7611069813622!3d35.6923898751528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188daedab5f825%3A0x2c52f46c6376c4d!2z6Yar5YGl5rOw5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1696147105243!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4614.456446037608!2d139.7611069813622!3d35.6923898751528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188daedab5f825%3A0x2c52f46c6376c4d!2z6Yar5YGl5rOw5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1696147105243!5m2!1sja!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});