<template>
  <div class="inner-banner breadcrumb-area-policy main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Privacy Policy</h4>
                        <h1 class="mt-3">個人情報保護方針</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
           <p style="width:100%" class="color-1"><span>個人情報保護方針</span></p>
      </div>
      <div class="row post_content">
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">{{HomeInfo.CompanyName}}（以下、当社）は、国際医療コーディネーター業務、保険紹介サービス並びにこれらに付随する業務を遂行するにあたり、社会的使命と責任を十分に自覚し、本人の権利の保護、個人情報に関する法規制の遵守に全社を挙げて取り組みます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>1. </span>当社は、事業の遂行に必要な範囲に限定して、個人情報を適切に取得、利用します。個人情報は特定された利用目的の範囲内で利用し、あらかじめご本人の同意を得ることなく目的外利用を行いません。また、そのための措置を講じます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>2. </span>当社は、ご本人の同意を得た場合や法令等により正当な理由がある場合を除き、個人情報を第三者に提供いたしません。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>3. </span>当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>4. </span>当社は、保有する個人情報の漏えい、滅失又はき損を防止するための対策を講じるとともに、個人情報保護の観点で問題があると判断した場合には、速やかに是正措置を講じます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>5. </span>当社は、個人情報の取扱い及び個人情報保護マネジメントシステムに関する苦情・相談窓口を設置し、対応いたします。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><span>6. </span>当社は、ＪＩＳ規格（ＪＩＳＱ１５００１）に基づく個人情報保護マネジメントシステムを運用し、最新の国際医療の動向、社会的要請の変化、経営環境の変動等を考慮して、継続的に見直し改善していきます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%;text-align: right;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">制定日：2023年3月</p>
        <!-- <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">最終改訂日：－</p> -->
        <p style="width:100%;text-align: right;"  data-aos="fade-right" :data-aos-duration="500">代表取締役　{{HomeInfo.Representative}}</p>
        <p style="width:100%;text-align: right;" data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%;text-align: right;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">【個人情報保護方針に関するお問合せ先】</p>
        <p style="width:100%;text-align: right;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">{{HomeInfo.CompanyName}}</p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">個人情報お問い合わせ窓口</p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">TEL：<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
        <p style="width:100%;text-align: right;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">メール：<a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a></p>

        <!--PDF View-->
        <!--
        <div :data-aos-duration="500" class="row align-items-center pdf-View" data-aos="fade-right" style="width:100%">
          <iframe :src="src" frameborder="0" height="100%" width="100%"></iframe>
        </div>
        -->
      </div>
       <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
       <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
           <p style="width:100%" class="color-1"><span>個人情報の取り扱いについて</span></p>
      </div>
      <div class="row post_content">
       <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">当社が取り扱うお客さま等の個人情報について、以下のとおり公表いたします。</p>
       <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
       <h2 data-aos="fade-right" :data-aos-duration="500"> 1. 個人情報の利用目的</h2>
       <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">当社は、お客さま等の個人情報を以下の目的で利用いたします。この他の利用目的で個人情報を利用する場合には、個人情報を取得するときに、その利用目的と問合せ先を明示します。 なお、これらの利用目的の範囲を超えてお客さまの個人情報を利用する場合は、改めて利用目的をお知らせし、同意を得ることとします。ただし、取得状況からみて利用目的が明らかであると認められる場合は除きます。</p>
       <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">弊社の保有個人データの利用目的</p>
       <div style="width:100%" >
           <table class="yikt_recruit_table" data-aos="fade-right">
                  <tbody>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>顧客情報</div></td>
                      <td>
                        <div>
                           弊社サービスを提供する目的及び事務連絡・アフターフォロー等それに付帯する業務を実行するため、DM発送等弊社のサービスをご紹介するため
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>問合せ情報</div></td>
                      <td>
                        <div>
                           製品やサービス等に関する意見、要望、問合わせについての受付と回答するため
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>取引先情報</div></td>
                      <td>
                        <div>
                           弊社との取引に関する事務その他それに付帯する業務を実行するため
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>従業者情報</div></td>
                      <td>
                        <div>
                           従業者管理に係わる業務に利用するため（業務・労務・人事管理業務、給与関連業務、福利厚生業務など）
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>採用情報</div></td>
                      <td>
                        <div>
                           採用に係わる業務に利用するため（採用に関する情報提供、採用可否判断、採用業務に関する連絡など）
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>本人および代理人の情報（開示等請求時）</div></td>
                      <td>
                        <div>
                           開示等の求めに回答するため
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
       </div>
        <p style="width:100%;margin-top:15px"  data-aos="fade-right" :data-aos-duration="500">その他個人情報の利用目的</p>
        <div style="width:100%" >
           <table class="yikt_recruit_table" data-aos="fade-right">
                  <tbody>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>受託業務にて取り扱う個人情報</div></td>
                      <td>
                        <div>
                           委託された業務を実行するため
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>求人サイトから取得した情報</div></td>
                      <td>
                        <div>
                           求職者、応募者に対する連絡、採用選考に利用するため
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right" :data-aos-duration="500">
                      <td class="yikt_recruit_title_td"><div>協力会社から取得する技術者情報</div></td>
                      <td>
                        <div>
                           就業先紹介、業務連絡、入館証作成、技術者の労務管理、就業先の人材管理に利用するため
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
        </div>
        <p style="width:100%;margin-top:10px"  data-aos="fade-right" :data-aos-duration="500"></p>
        <h2 data-aos="fade-right" :data-aos-duration="500">2. 当社の保有個人データの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止、第三者提供記録の開示のご請求手続き</h2>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">当社では、お客さまの保有個人データについて、本人またはその代理人から、開示・変更・削除・利用停止・第三者提供の停止等の求めがあった場合、当社所定の方法によって対応します。具体的な方法については、個別にご案内しますので、下記（３）お問い合わせ先までご連絡ください。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（１） 手続き</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;ご請求は、郵送・電話・電子メールの送信によってご連絡ください。ご請求内容確認後、当社所定の請求様式をお送りしますので、必要事項をご記入の上、ご返送ください。その際、請求者または代理人がご本人であること、また代理人が代理権を有することを当社所定の方法により確認させていただきます。なお、保有個人データの削除、利用の停止、消去及び第三者への提供の停止をお求めの場合は、当社サービスのご提供が出来なくなることをご了承ください。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（２）手数料等</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;当社への手数料はいただきませんが、請求者にかかる切手代、電話代等のすべての費用はご請求者様にご負担いただきます。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（３）お問い合わせ先</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&nbsp;&emsp;【個人情報お問い合わせ窓口】</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;窓口責任者：個人情報保護管理者</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;住所：〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;TEL：<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;メール：<a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a></p>
        <p style="width:100%;"  data-aos="fade-right" :data-aos-duration="500"></p>
        <h2 data-aos="fade-right" :data-aos-duration="500">3. 保有個人データの取扱いに関する苦情・相談について</h2>
        <p style="width:100%;"  data-aos="fade-right" :data-aos-duration="500">当社の保有個人データの取扱いに関する苦情・相談につきましては、上記２．（３）の「お問い合わせ先」までお寄せください。</p>
        <p style="width:100%;"  data-aos="fade-right" :data-aos-duration="500"></p>
        <h2 data-aos="fade-right" :data-aos-duration="500">4. 安全管理措置</h2>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">当社が講じる個人情報の安全管理措置は、以下のとおりです。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（１）基本方針の策定</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;個人情報の適正な取扱いの確保のため「関係法令・ガイドライン等の遵守」、「個人情報の安全管理措置に関する事項」、「質問及び苦情相談の窓口」等について、「個人情報保護方針」を策定しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（２）個人情報の取扱いに係る規律の整備</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;当社の個人情報保護規程において、個人情報の取得、利用、保存等を行う場合の基本的な取扱い方法を整備しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（３）組織的安全管理措置</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;個人情報の管理に関する責任者の設置とその役割の明確化、個人情報の取扱状況の点検及び監査の体制整備と実施、漏えい事案等に対する体制の整備等を実施しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（４）人的安全管理措置</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;従業者との「非開示契約」の締結、就業規則等の整備、個人情報の取扱いに関する留意事項についての従業者への周知徹底及び研修等を実施しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（５）物理的安全管理措置</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;紛失・盗難を防ぐための書類・外部媒体等の施錠保管、裁断・焼却・溶解等復元が著しく困難な手段での個人情報廃棄、責任者の承諾なく個人情報を持ち出すことの禁止、個人情報を持ち運ぶ際の暗号化や封緘等の措置を実施しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（６）技術的安全管理措置</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;個人情報を取り扱う機器への外部からの不正アクセスまたは不正ソフトウェアから保護する仕組みの導入、担当者及び取り扱う個人データの範囲の限定などのアクセス制御の実施、不正アクセスを防止するための強固なパスワードの使用、IDの共有禁止、暗号化設定可能な機器の使用等の措置を実施しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">（７）外的環境の把握</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">&emsp;&emsp;個人情報の一部を含めた情報の保管についてクラウドサービスを利用しており、これらの個人情報は、当該事業者の管理のもと、アメリカ合衆国に保管されております。当社は、アメリカ合衆国の個人情報の保護に関する制度を把握した上で、必要かつ適切な安全管理措置を実施しています。</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">■参考：個人情報保護委員会　外国における個人情報の保護に関する制度等の調査</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500"><a target="_blank" href="https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/#gaikoku">https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/#gaikoku</a> </p>
        <p style="width:100%;"  data-aos="fade-right" :data-aos-duration="500"></p>
        <h2 data-aos="fade-right" :data-aos-duration="500">5 当社の名称・住所・代表者の氏名</h2>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">{{HomeInfo.CompanyName}}</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</p>
        <p style="width:100%;margin-bottom:5px"  data-aos="fade-right" :data-aos-duration="500">代表取締役　{{HomeInfo.Representative}}</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">制定日：2023年3月</p>
        <!-- <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">最終改訂日：－</p> -->
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">個人情報保護管理者：代表取締役　{{HomeInfo.Representative}}</p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">TEL：<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
        <p style="width:100%;text-align: right;margin-bottom:5px" data-aos="fade-right" :data-aos-duration="500">メール：<a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a></p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
     return {
      viewerUrl: '/assets/pdfVeiw/web/viewer.html',
      fileUrl: '/assets/pdf/policy_220401.pdf'
    }
  },
  computed: {
    src() {
      return `${this.viewerUrl}?file=${encodeURIComponent(this.fileUrl)}&zoom=page-width`
    }
  }
}
</script>

<style scoped>
@media (max-width: 790px) {
    .sm_no_show {
        display: none!important;
    }
    .pb10 {
        padding-bottom: 15px!important;
    }
    .page-header {
        padding-top: 119px!important;
    }
    .banner-background-02 {
        top: 282px !important;
    }
    .banner-background-01 {
        top: 271px !important;
    }
    .service-one {
        padding-top: 30px !important;
    }
    .pdf-View {
        height: 265px !important;
    }
}

.pdf-View {
    height: 750px;
    box-shadow: 2.5px 4.33px 15px 0 rgb(0 0 0 / 15%);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 8px;
}

.pdf-View iframe {
    border-radius: 8px;
}
</style>
