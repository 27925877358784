<template>
<div class="inner-banner breadcrumb-area-philosophy main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Management Philosophy</h4>
                        <h1 class="mt-3">経営理念</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">  
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
           <p style="width:100%" class="color-1"><span>Management Philosophy</span></p>
      </div>
      <div class="row post_content">
          <h2>創造と信頼</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
           日中国際医療サービスの統合に尽力し、<br/>
           最高の専門家、病院、そして最高の治療効果、医療経験を提供し、<br/>
           信頼できる医療サービスの専門家になるようにがんばっている
           </p>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
          <h2>コミュニケーションと進歩</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
           私たちは、<br/>
           医師と患者の両方の立場に立って、<br/>
           専門的な医学的背景と開かれた国際的視野で仕事を実行し、<br/>
           「大きな成果を上げ、意味のあることをするために国と人々のために最善を尽くすこと」という企業価値を実践する
           </p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Philosophy",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>
