<template>
  <div class="inner-banner breadcrumb-area-info1 main-banner">
    <div class="image-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Business</h4>
                        <h1 class="mt-3">事業内容</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <section class="auninfo_contact cantact_v2 section_padding margin-top-about breadcrumb-area-biz">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Business</span></p>
        </div>
        <div class="row">
             <!-- /.col-lg-6 -->
            <div class="col-lg-6 d-flex flex_phone">
                <div class="my-auto">
                    <div class="about-one__image wow slideInDown" ddata-aos="fade-right" :data-aos-duration="500" style="top:0">
                        <img src="/assets/images/choose-1.png" alt="" style="width: 100%;margin: 0 auto;margin-top: 50px;">
                    </div>
                    <!-- /.about-one__image -->
                </div>
                <!-- /.my-auto -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-12">
                <div class="about-one__content" >
                    <div class="about-one__box-wrapper">
                        <!-- /.about-one__box -->
                        <div class="about-one__box" id="b1">
                            <div class="about-one__box-icon">
                                <i class="fa fa-users-medical"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>専門医セカンドオピニオン（病歴資料・遠隔オンライン）</h3>
                                <p>
                                    日本の医療機関にご自身の病状や診断についての相談をされたい海外の患者様へのサービスです。本国での治療方法や診断に不安や不満のある患者様も多い中、
                                    患者様の納得のいく治療法を選択することができるよう、治療の進行状況、次の段階の治療選択などについて、本国で治療を受けている医療機関とは別に、
                                    違う医療機関の医師に「第2の意見」セカンドオピニオンを求めることができます。その後治療をどうするか等を判断し、そのまま本国での治療か、来日での治療を決める事も可能です。セカンドオピニオンには各医療機関の必要とする医療情報の詳細などが事前に必要となります。
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <div class="about-one__box" id="b2">
                            <div class="about-one__box-icon">
                                <i class="fa fa-wave-pulse"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>重症医療</h3>
                                <p>
                                    日本での治療を希望する海外の患者様へコーディネート、サポートを行います。
                                    受入医療機関とのマッチング、調整、資料の翻訳、治療期間中の医療通訳の手配など、日本での治療にかかわる全てをコーディネート致します。
                                    医療情報をもとに患者様の状態を確認し、治療方針、エリアや費用などについての希望事項や不安要素などを十分に考慮した上で受け入れ可能な医療機関を確認します。
                                    医療機関としっかりと連携を行い、納得して頂いた上で日本での治療を行うかどうかを判断頂きます。患者様ご本人のみならず同行される家族の方がたに向けてのサポートも行います。
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-12">
                <div class="about-one__content" id="b3">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-hospital-user"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>健診・精密検査</h3>
                                <p>
                                    世界のトップレベルとわれる日本の予防医学。最先端の医療機器を用い、かつ非常に精度、練度の高い画像診断スキルを持つ医師により的確に発見する事により早期診断、早期治療につなげる事が可能です。
                                    医療ツーリズムで希望が多い検査はやはりPET-CTです。日本の画像診断技術が高く、信頼度も高い、また費用自体も中国内での検査と比較しても高額ではないという事が人気の要素ではないかと考えております。
                                    初めて日本で人間ドックを受診する、今までPET-CTを受診した事がないという方はほぼPET-CTを希望されます。
                                    ただし、PET-CTを受けた事が近年ある方や妊娠を希望されてる方、スケジュールが合わない方などはPET-CT以外での検査、MRI/MRA、CT、内視鏡、超音波検査などの画像検査や腫瘍マーカーなどの各種検査でのがん検査でも十分高い満足度を感じていらっしゃいます。
                                    医療ツーリズムでの人間ドックを積極的に受け入れていらっしゃる医療機関の中には外国人用の待合室を設けていらっしゃる場合もあり、満足度を高める要因にも一因となっているようですが必須でないと感じております。
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>

                <div class="about-one__content" id="b4">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-heart-pulse"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>アンチエイジング・亜健康対策</h3>
                                <p>
                                    体性幹細胞による再生医療を受けれます！<br/>
                                    体性幹細胞による幹細胞再生医療は私たちの体の至る所にある多様性な幹細胞を使用します。<br/>
                                    この細胞は何にでもなれるのではなく骨髄の中に存在する、間葉系幹細胞は筋肉、神経、軟骨などに分化する多分化能を持つことが分かってきました。<br/>
                                    つまりips細胞やES細胞のように色々な細胞になることが可能なのです。<br/>
                                    そして骨髄の中に存在する間葉系幹細胞と似た性質を持つ幹細胞が皮下脂肪の中にも多く存在することが最近の研究で分かって来ました。<br/>
                                    これは、脂肪由来間葉系細胞と言われて、組織幹細胞の中で採取が簡単で組織量も豊富に存在することから治療細胞として注目されています。<br/>
                                    この注目されている体性幹細胞を使用した<br/>
                                    幹細胞再生医療を受けることができます！<br/><br/>

                                    免疫療法で諦めない癌治療をご提案します。<br/>
                                    当社がご紹介するNKT細胞を用いた免疫療法を実施するクリニックや病院は担当医師が治療内容を丁寧に一つ一つ分かりやすくご説明します。<br/>
                                    治療開始から当社の医療コーディネーターがマンツーマンでしっかりとサポートしますので、安心してお任せいただけます。<br/>
                                    治療中のどんな些細なこともお申し付けください。<br/>
                                    もちろん担当医師のフォローも万全です。<br/>
                                    患者さん自身の免疫を活性化するNKT細胞を用いた免疫療法<br/>
                                    NK細胞とT細胞の双方を併せ持つNKT細胞を用いて癌そのものを直接攻撃するのではなく、NKT細胞そのもの自己免疫系を活性化することで強力な抗腫瘍効果が発揮されるのがNKT免疫療法です。<br/>
                                    また癌種類を問わずに自己免疫を活性化することで、どなたでも効果が期待できます。<br/>
                                    NKT免疫療法は治療による副作用がほとんどなく、お体に優しい癌治療の1つだと言えます。<br/>
                                    ご不明な点、ご質問等がございましたら、当社の医療コーディネーターがご自身のご希望にあったNKT細胞免疫療法を受けることができる医療施設をご紹介致します。<br/>
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>

                <div class="about-one__content" id="b5">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-syringe"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>美容医療</h3>
                                <p>
                                   しわ、しみ、たるみ、毛穴の開き、ニキビなど、老化や肌荒などの改善、アンチエイジングの施術を日本の優れた美容医療機関での受診をコーディネートを致します。<br>
                                    「プラセンタ注射」やご自身の血液中に含まれる多血小板血漿（PRP）を利用した「PRP皮膚再生療法」、「ボトックス注射」、「フェザーリフト」等、ご要望に沿ったプランを提供致します。
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
                <!-- /.about-one__content -->
            </div>
        </div>  </div>
        <br/>
        <br/>
    </div>
 </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Business",
  components:{ BreadCrumb },
  setup() {}
}
</script>

<style scoped>

</style>
